import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactHtmlParser from 'react-html-parser'
import ContentBlock from 'components/atoms/ContentBlock'
import BigButton from 'components/molecules/BigButton'
import Typography from 'components/atoms/Typography'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import './CarouselBigButton.scss'

function CarouselBigButton(props) {
  const sliderSettings = {
    dots: true,
    dotsClass: `slick-dots primary`,
    infinite: false,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  }
  const { data } = props
  const description = get(data, 'description.value')
  const title = get(data, 'title')
  const items = get(data, 'items')
  const sliderRef = React.createRef(null)

  return (
    <Container id={props.data.id} className="carousel-bigButton" fluid="fluid">
      <Container className="pt-5 pb-md-4">
        <Row>
          <Col bgcolor="primary" xs={12}>
            <ContentBlock>
              <Typography variant="h2" color="primary" cssClass="mb-3 title-semibold-lg">
                {title}
              </Typography>

              {description && (
                <Typography variant="span" color="gray-100" cssClass="bloco-conteudo-text mb-4">
                  {ReactHtmlParser(description)}
                </Typography>
              )}
            </ContentBlock>
          </Col>
        </Row>
        <Slider ref={sliderRef} {...sliderSettings}>
          {Array.isArray(items) &&
            items
              .filter((i) => i.entity)
              .map(({ entity }, index) => (
                <BigButton
                  fullWidth
                  footer
                  id={get(entity, 'id')}
                  icon={get(entity, 'icon.entity.media.entity.fieldMediaFile.entity.url')}
                  onClick={() => {
                    sliderRef.current.innerSlider.track.node.classList.add('no-transform')
                    sliderRef.current.innerSlider.list.classList.add('no-transform')
                  }}
                  onCloseRedirectModal={() => {
                    sliderRef.current.innerSlider.track.node.classList.remove('no-transform')
                    sliderRef.current.innerSlider.list.classList.remove('no-transform')
                  }}
                  title={get(entity, 'title')}
                  theme={get(entity, 'theme.entity.classes')}
                  url={get(entity, 'link.url.path', '')}
                  key={get(entity, 'uuid', index)}
                />
              ))}
        </Slider>
      </Container>
    </Container>
  )
}

CarouselBigButton.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CarouselBigButton
